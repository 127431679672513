/**
 * Document defaults (html, body)
 */

html, body {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  width: 100%;
  overflow-x: hidden;
}
.limit-width {
  // max-width: $maxWidth;
  margin: 0 auto;
}
main {
  width: 100%;
	padding: 0 $padding-side $padding-side $padding-side;
  @include media-breakpoint-down(md) {
	  padding: 0 $padding-side-mobile $padding-side-mobile $padding-side-mobile;
  }
}
section {
  display: flex;
  flex-direction: column;
}
* {
  transition: $transition-base;
}
section {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}
.mb {
  margin-bottom: 80px;
  @include media-breakpoint-down(md) {
    margin-bottom: 40px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}
.textcenter {
  text-align: center;
}
.richtext {
  margin: 30px 0;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  z-index: 200;

  &.hidden {
    display: none;
  }
}
.content-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
