/**
 * Paragraph
 */

$paragraph-margin-bottom: 1rem !default;
p, a {
  font-family: 'localCalibri', 'sans-serif'
}
p {
  margin: 0;
  line-height: 1.3rem;
  font-weight: 500;
  font-size: 1.1rem;
  font-family: $text-font;
}

b, strong {
  font-weight: 700;
}

h2 {
  font-family: $head-font;
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 35px;
  margin-top: 0;
}
.rich-text {
  margin-bottom: 40px;
  & h2 {
    font-family: $text-font;
    font-weight: 600;
		font-size: 27px;
		line-height: 26px;
	}
  & h3 {
    font-family: $text-font;
  }
	& p {
    font-size: 18px;
    line-height: 26px;
		margin-bottom: 10px;
	}
}
.richtext {
	margin-bottom: 30px;
	& h2 {
		font-size: 22px;
		line-height: 26px;
	}
  & h3 {
    font-family: $text-font;
  }
	& p {
		margin-bottom: 10px;
	}
}
