/**
 * Media
 */

// Fluid images for responsive purposes.

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  width: 100%;
  transition: all .3s ease-out;
	background-size: cover;
}

.lazyload {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
}
// If a `width` and/or `height` attribute have been explicitly defined, let’s not make the image fluid.

img[width],
img[height] {
  max-width: none;
}

figure {
  margin: 0;
}

object,
iframe,
embed,
canvas,
video,
audio {
  max-width: 100%;
}
.video-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
  width: 100%;
  margin-bottom: 30px;
}
.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}