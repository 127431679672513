.product-gallery {
	& .overlay {
		display: none;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		padding: 100px;
		background-color: rgba($black, .8);
		z-index: 250;
		@include media-breakpoint-down(md) {
			padding: 20px;
		}
		& .close {
			position: absolute;
			top: 30px;
			right: 30px;

			& svg {
				width: 50px;
				height: 50px;
				fill: $white;
			}
		}

		&.active {
			display: flex;
		}

		& .image-overlay {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 80%;
			height: 80vh;
			& img {
				width: auto;
				height: 100%;
			}
			@include media-breakpoint-down(md) {
				width: 100%;
				& img {
					width: auto;
					height: auto;
				}
			}
			& .image-control {
				width: 100%;
				display: flex;
				justify-content: space-between;
				position: absolute;
				padding: 0 80px;
				@include media-breakpoint-down(md) {
					padding: 0 0px;
				}
				& button {
					background: none;
					border: none;
					cursor: pointer;

					& svg {
						fill: $white;
						width: 30px;
						height: 30px;
						@include media-breakpoint-down(md) {
							width: 30px;
							height: 30px;
						}
					}
				}
			}

			& img {
				margin-bottom: 20px;
			}
		}
	}

	& ul {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
		margin-top: 40px;
		flex-wrap: wrap;
		@include media-breakpoint-down(md) {
			justify-content: center;
		}
		& li {
			cursor: pointer;
			@include media-breakpoint-down(md) {
				width: auto;
				margin: 0;
			}
			& img {
				width: 200px;
				margin: 0 20px 20px 0;

				@include media-breakpoint-down(sm) {
					width: 100%;
					margin: 0 0 20px 0;
				}
			}
		}
	}
}