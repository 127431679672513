.product-menu {
	display: flex;
	margin-bottom: 15px;

	& .filter-menu {
		margin-bottom: 20px;
	}
	& ul {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		margin: 0;

		& li {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 20px 20px 0;
			&:first-child {
				margin-left: 0;
			}

			border-bottom: 2px solid rgba($blue-light, .1);
			
			&.active, &:hover{
				border-bottom: 2px solid $black;
			 }
			& a, & button {
				padding: 10px 20px 3px 20px;
				background-color: rgba($blue-light, .1);
				position: relative;
				box-sizing: border-box;
				font-size: 25px;
				text-decoration: none;
				color: $black;
				font-family: $head-font;
				border: none;
				@include media-breakpoint-down(md) {
					font-size: 23px;
					padding: 5px 10px;
				}
				 
			}
		}
	}
}