.contact-card {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 40px;
	& .contact-image {
		margin-bottom: 20px;
	}

	& .contact-info {
		& p {
			margin-bottom: 5px;
			&:first-of-type {
				font-weight: 800;
			}
			& a {
				font-size: 16px;
				color: $black;
			}
		}
	}
}
