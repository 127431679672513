.contact-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 40px 60px;
	margin: 20px 0 0 0;
	box-sizing: border-box;
	background-color: rgba($blue-light, .2);
	width: auto;
	& h3 {
		margin: 0;
		margin-bottom: 15px;

	}
	& p{
		margin-bottom: 5px;
		font-size: 14px;
		&:last-of-type {
			margin-bottom: 0;
		}
		& a {
			color: $black;
			text-decoration: none;
		}
	}
}
