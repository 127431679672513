.banner-top {
	display: flex;
	position: relative;
	height: auto;
	width: 100%;
	overflow: hidden;
    @include media-breakpoint-down(md) {
        height: 250px;
    }
    & .banner-image {
        width: 100%;
    }
	& .banner-title {
		position: absolute;
		bottom: 0;
        @include media-breakpoint-down(md) {
            left: $padding-side-mobile;
        }
        @include media-breakpoint-down(xl) {
            left: $padding-side;
        }
		z-index: 100;
		& h2 {
			margin: 0;
			font-size: 130px;
			line-height: 76px;
			color: $white;

            @include media-breakpoint-down(md) {
                font-size: 45px;
                line-height: 25px;
            }
		}
	}
	& .bg-img {
		height: 500px;
		width: 100%;
	}
}
.banner-top-tekst {
	padding: 60px $padding-side;
    p {
        font-size: 25px;
        line-height: 33px;
    }
    @include media-breakpoint-down(md) {
        padding: 40px $padding-side-mobile;
    }
}

.banner-text {
    background-color: $blue;
    padding: 60px $padding-side;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(md) {
        padding: 40px $padding-side-mobile;
    }
    & .rich-text {
        & h2, & h3, & p, & a {
            color: $white;
            
        }
        h2 {
            font-size: 40px;
            line-height: 48px;
        }
        h2, h3, h4 {
            font-family: $head-font;
            font-weight: 400;
        }
        & p {
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 20px;
        }
        & a {
            font-size: 20px;
            line-height: 26px;
            text-decoration: none;
            border-bottom: 1px solid $white;
            padding-bottom: 2px;
            font-weight: 600;
        }
    }

    & .text-logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        @include media-breakpoint-down(md) {
            width: 100%;
            height: auto;
        }
        & svg {
            height: 250px;
            @include media-breakpoint-down(md) {
                height: 150px;
            }
        }
    }
}
.banner {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
    @include media-breakpoint-down(md) {
		text-align: center;
        padding: 10px;
	}
    & h2 {
        color: rgba($white, .8);
        z-index: 120;
        font-size: 65px;
        max-width: 600px;
        @include media-breakpoint-down(md) {
            font-size: 45px;
        }
    }
    & .overlay {
        z-index: 100;
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba($black, .7);
    }
}
