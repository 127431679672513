.categoricards {
	background-color: $white;
	padding: 60px $padding-side;
	@include media-breakpoint-down(md) {
		padding: 20px;
	}
	& .col-md-4 {
		margin-bottom: 30px;
	}
	& .cat-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: $black;
		text-decoration: none;
		height: 100%;
		padding-bottom: 20px;

		& .cat-card-content {
			background-color: rgba(#3659B4, .07);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			padding: 20px;
		}

		& h3 {
			font-size: 30px;
			font-family: $head-font;
			font-weight: 300;
			margin: 0;
		}

		& p {
			font-size: 14px;
			font-weight: 600;
			max-width: 50%;
			@include media-breakpoint-down(md) {
				max-width: 80%;
			}
		}

		& img {
			width: 140px;
			height: 140px;
			margin: 25px 0;
		}
	}
}