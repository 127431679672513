/**
 * Shame file
 *
 * This is a place for hacky, nasty code that should be replaced
 * and moved to the correct partial. Ideally, this is empty.
 */

/*
  Sass Techniques
  CSS Snippets
*/
.row {
  margin-left: -30px;
  margin-right: -30px;
}