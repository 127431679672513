.productcard {
	text-decoration: none;
	height: 100%;
	display: flex;
	flex-direction: column;

	& .image {
		position: relative;

		& h2 {
			position: absolute;
			left: 20px;
			bottom: 10px;
			margin-bottom: 0;
			color: $white;
		}
	}

	& .product-content {
		display: flex;
		flex-direction: column;
		background-color: rgba($blue-light, .2);
		padding: 20px;
		align-items: flex-start;
		margin-bottom: 30px;
		height: 100%;
		justify-content: space-between;

		& ul {
			display: flex;
			list-style: none;
			padding: 0;
			flex-wrap: wrap;
			margin: 0;
			width: 100%;

			& li {
				display: flex;
				align-items: center;
				width: 50%;
				color: $black;
				margin-bottom: 20px;
				flex-grow: 1;
				
				@media (max-width: 465px) {
					width: 100%;
				}

				&:nth-last-child(-n+2) {
					margin-bottom: 0;

					@include media-breakpoint-down(md) {
						margin-bottom: 20px;
						width: 100%;
					}
				}

				&:nth-last-child(-n+1) {
					margin-bottom: 0;

					@include media-breakpoint-down(md) {
						width: 100%;
					}
				}

				&:nth-child(even) {}

				& p {
					font-size: 16px;
					text-decoration: none;
					word-break: break-word;
				}

				& .icon {
					margin-right: 20px;
					width: 20px;
						height: 20px;
					& img {
						width: 20px;
						height: 20px;
						max-width: none;
					}
				}
			}
		}

		& .link {
			margin: 15px 0;
			color: $black;
			margin: 25px auto 15px;
			font-weight: 600;
			line-height: 28px;
			border-bottom: 2px solid $black;
			text-transform: uppercase;

			@include media-breakpoint-down(md) {
				margin: 20px 0;
			}
		}
	}
}