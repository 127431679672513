.equipment-wrapper {
	& .equipment {
		background-color: rgba($blue-light, .2);
		margin-bottom: 30px;
		& .info {
			padding: 20px 30px;
			box-sizing: border-box;
			& h2 {
				font-family: $text-font;
				font-weight: 600;
				font-size: 26px;
				margin-bottom: 10px;
			}
			& p {
				font-size: 18px;
			}
			& .price {
				margin-bottom: 0;
			}
			& button {
				background: none;
				border: none;
				cursor: pointer;
				margin: 15px 0;
				font-family: $text-font;
				font-weight: 600;
				padding: 0;
				border-bottom: 2px solid $black;
				padding-bottom: 5px;
				transition: all .2s ease-in-out;
			}

			& .info-wrapper {
				display: none;
				flex-direction: column;
				margin-top: 15px;
				& .equipment-info {
					margin-bottom: 20px;
					& h3 {
						margin: 15px 0;
						font-family: $text-font;
						font-size: 16px;
					}
					
					& .product-detail {
						margin-bottom: 0;
						& li {
							& .icon {
								margin-right: 15px;
								width: 18px;
							}
							& p {
								font-weight: 500;
								font-size: 20px;
							}
						}
					}
				}
			}
		}
	}
}