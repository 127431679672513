.contact-wrapper {
	display: flex;
	justify-content: flex-start;
}
.contact-list {
	&.high-lighted {
		align-items: flex-start;
		background-color: rgba($blue-light, .2);
		padding: 20px 60px;
	}
	& h3 {
		font-size: 1em;
		font-family: $text-font;
		margin-bottom: 10px;
	}
	& p {
		font-size: 14px;
		margin-bottom: 5px;
		& a {
			color: $black;
			text-decoration: none;
		}
	}
}
.product-link { 
	margin: 20px 0;
	& button {
		cursor: pointer;
		padding: 0;
		font-family: $text-font;
		font-weight: 600;
		background: none;
		border: none;
		font-size: 20px;
		border-bottom: 1px solid $black;
		margin-bottom: 30px;
	}
	& a {
		color: $black;
		font-weight: 600;
		font-size: 18px;
	}
}