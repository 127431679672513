/*!
 * Project: Project Name
 * Author: Name
 * E-mail: E-mail
 * Website:
 */

/**
 * This is where all of the stylesheets are compiled.
 * They are processed in the order they are imported
 * to ensure the consistent increase of specificity.
 *
 *    ITCSS Methodology: Inverted Triangle CSS
 *
 *    **************   1. Settings
 *     ************    2. Tools
 *      **********     3. Generic
 *       ********      4. Elements
 *        ******       5. Objects
 *         ****        6. Components
 *          **         7. Utilities
 *
 * 1. Settings.........Global configuration and variables.
 * 2. Tools............Functions and mixins.
 * 3. Generic..........Ground zero styles.
 *                     (normalizing.css, box-sizing etc...)
 * 4. Elements.........Unclassed (bare) HTML element
 *                     (like H1, Ul, A etc...)
 * 5. Objects..........Common non-cosmetic structural design patterns.
 *                     e.g containers, rows, grids, colums etc...
 * 6. Components.......Specific cosmetic elements of UI.
 *                     e.g. buttons, forms, header, footer etc...
 * 7. Utilities........Helpers and overrides.
 *
 *    Modules..........Multi-part components.
 *                     e.g. Navbar (HTML, CSS and JS).
 *    Shame............All the CSS, hacks and things we are not proud of.
 */

@import "settings/_animation.scss";
@import "settings/_colors.scss";
@import "settings/_transitions.scss";
@import "settings/_variables.scss";
@import "tools/_clearfix.scss";

@import "objects/_bootstrap.grid.scss";
@import "components/_buttons.scss";
@import "components/_contactbox.scss";
@import "components/_divider.scss";
@import "components/_footer.scss";
@import "components/_header.scss";
@import "components/_product.scss";
@import "components/_productcontact.scss";
@import "components/_productmenu.scss";
@import "components/_productprislist.scss";
@import "components/_terms.scss";
@import "components/_title.scss";

@import "elements/_document.scss";
@import "elements/_lists.scss";
@import "elements/_media.scss";
@import "elements/_paragraphs.scss";
@import "elements/generic/_box-sizing.scss";
@import "elements/generic/_font-face.scss";
@import "elements/generic/_normalize.scss";
@import "elements/generic/_print.scss";
@import "C:/Development/Produktionen/Frontend/src/modules/banner/banner.scss";
@import "C:/Development/Produktionen/Frontend/src/modules/categoriescards/_categoriescards.scss";
@import "C:/Development/Produktionen/Frontend/src/modules/clients/_clients.scss";
@import "C:/Development/Produktionen/Frontend/src/modules/contactcard/_contactcard.scss";
@import "C:/Development/Produktionen/Frontend/src/modules/equipment/_equipment.scss";
@import "C:/Development/Produktionen/Frontend/src/modules/hero/_hero.scss";
@import "C:/Development/Produktionen/Frontend/src/modules/product/_product-page.scss";
@import "C:/Development/Produktionen/Frontend/src/modules/productcards/_buscard.scss";
@import "C:/Development/Produktionen/Frontend/src/modules/productcards/_productcard.scss";
@import "C:/Development/Produktionen/Frontend/src/modules/text/_textcomp.scss";
@import 'shame';
