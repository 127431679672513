.terms-page {
	height: 100%;
	padding-top: 120px;
	& h1{
		font-family: $text-font;
		font-size: 24px;
	}
	& .term {
		margin-bottom: 40px;
		padding-right: 80px;
		@include media-breakpoint-down(md) {
			padding-right: 0;
		}
	}
	.contact-wrapper {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
	}
}