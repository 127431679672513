/**
 * Lists
 */

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

li {
  margin-bottom: .5rem;
  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-top: .5rem;
  }
}
.no-list-style {
  list-style: none;
  padding-left: 0;
  margin: 0;
  @media (max-width: 768px) {
    padding-left: 0;
  }
}
