.hero {
	z-index: 0;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 100%;
	min-height: 550px;
	@include media-breakpoint-down(md) {
		min-height: 350px;

	}
	& .hero-image {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		& img {
			z-index: -2;
			position: absolute;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			top: 50%;
			left: 50%;
			width: 100%;
			height: 100%;
			border-style: none;
			object-fit: cover;
			font-family: "object-fit: cover;";
			vertical-align: middle;
			background-size: cover;
			min-height: 1px;
		}
	}

	& h1 {
		font-size: 95px;
		letter-spacing: 3px;
		color: $white;
		font-family: $head-font;

		@include media-breakpoint-down(md) {
			font-size: 55px;
			text-align: center;
		}
	}
}