.text-comp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #e2ddd9;
    padding: 100px 400px;
    align-items: center;
    @include media-breakpoint-down(md) {
		padding: 40px;
        align-items: flex-start;
	}
    &.fullheight {
        height: 100vh;
        padding: 200px;
        @include media-breakpoint-down(md) {
			padding: 40px;
            height: auto;
		}
    }
    &.textcenter {
        text-align: center;
        @include media-breakpoint-down(md) {
            text-align: left;
		}
    }
    h2 {
        font-size: 35px;
        line-height: 40px;
        margin-bottom: 40px;
        @include media-breakpoint-down(md) {
            text-align: left;
		}
    }
    & .text {
        column-count: 2;
        max-width: 85%;
        @include media-breakpoint-down(md) {
            column-count: 1;
            max-width: 100%;
		}
        &.onecolumn {
            column-count: 1;
        }
        p {
            font-size: 18px;
            line-height: 30px;
            @include media-breakpoint-down(md) {
                text-align: left;
            }
        }
    }

    & .btn {
        margin-top: 45px;
    }
}