/**
 * Font Face
 */
 @font-face {
  font-family: 'MachineStd';
  src: url('/assets/fonts/MachineStd.otf') format("opentype"),;
}
@font-face {
  font-family: 'localCalibri';
  src: url('/assets/fonts/CalibriRegular.ttf');
}
@font-face {
  font-family: 'OpenSansRegular';
  src: url('/assets/fonts/OpenSans-Regular.woff') format('woff');
}
