/**
 * Imported via /node_modules
 *
 * Modifications
 * http://getbootstrap.com/docs/4.1/getting-started/theming/
 * Import bootstrap-grid.scss
 * The only change comparing to the original structure is that we don't import
 * whole "variables.scss" from Bootstrap as we don't need all of them.
 *
 * We comment // @import "variables"; and define Grids and Breakpoints variables directly here.
*/

/**
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "bootstrap/scss/functions";

// @import "variables";

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;
$enable-grid-classes:         true !default;

// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;

@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/mixins/grid-framework";
@import "bootstrap/scss/mixins/grid";

@import "bootstrap/scss/grid";
@import "bootstrap/scss/utilities/display";
@import "bootstrap/scss/utilities/flex";
