header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    z-index: 200;
    background-color: rgba($blue, .95);
    padding: $padding-top $padding-side;
    position: fixed;

    @include media-breakpoint-down(md) {
        padding: 20px 20px;
    }

    & .header {
        display: flex;
        position: relative;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        width: 100%;

        @include media-breakpoint-down(md) {
            flex-direction: row;
        }

        & a {
            text-decoration: none;
            z-index: 200;
        }

        @include media-breakpoint-down(md) {
            justify-content: space-between;
            align-items: flex-start;
        }

        .mobile-menu {
            display: none;

            @include media-breakpoint-down(md) {
                display: flex;
            }

            & svg {
                width: 30px;
                height: 30px;
            }
        }
    }

    & h1 {
        color: $white;
        font-family: $head-font;
        font-weight: 300;
        font-size: 45px;
        margin: 0;
        text-decoration: none;

        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
    }

    & nav,
    & .subMenu {
        @include media-breakpoint-down(md) {
            display: none;
            height: 100vh;
            background-color: $blue;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0
        }

        & ul {
            display: flex;
            list-style: none;
            margin-bottom: 0;
            padding: 0;

            @include media-breakpoint-down(md) {
                padding: 0 20px;
                flex-direction: column;
                width: 100%;
            }

            & li {
                display: flex;
                align-items: center;
                color: $white;
                margin: 0 0 0 30px;

                @include media-breakpoint-down(md) {
                    margin: 0 0 20px 0;

                }

                &.lang-selector {
                    & img {
                        width: 25px;
                    }
                }

                & a {
                    color: $white;
                    font-size: 23px;
                    text-decoration: none;

                    &.active {
                        border-bottom: 1px solid white;
                    }
                }
            }
        }
    }

    .subMenu {
        display: none;
        padding: 20px 0;
        width: 100%;
        justify-content: flex-end;
        margin-top: -30px;

        &.show {
            display: flex;
            margin-top: 0;
        }

        &.showdesktop {
            @media (min-width: 992px) {
                display: flex;
                margin-top: 0;
            }
        }

        & ul {
            & li {
                & a {
                    font-size: 18px;
                }
            }
        }
    }

    .close-menu,
    .close-submenu {
        display: none;

        @include media-breakpoint-down(md) {
            display: flex;
            position: absolute;
            top: 20px;
            right: 25px;

            & svg {
                width: 30px;
                height: 30px;
            }
        }
    }

    & nav {
        &.active {
            display: flex;
        }
    }
}