.product-details {
	& .detail-text {
		margin-bottom: 40px;

		& strong {
			font-weight: 700;
		}
	}

	& h3 {
		margin: 0 0 15px 0;
		font-family: $text-font;
	}
}
.product-detail {
	display: flex;
	list-style: none;
	padding: 0;
	flex-wrap: wrap;
	margin: 0 0 50px 0;

	& li {
		display: flex;
		align-items: center;
		width: 100%;
		color: $black;
		margin-bottom: 20px;
		& .icon{
			& img {
				max-width: 25px;
				width: 25px;
			}

		} 
		@include media-breakpoint-down(md) {
			width: 100%;
		}

		&:nth-last-child(-n+1) {
			margin-bottom: 0;

			@include media-breakpoint-down(md) {
				margin-bottom: 20px;
				width: 100%;
			}
		}

		&:nth-last-child(-n+1) {
			margin-bottom: 0;

			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}

		&:nth-child(even) {}

		& p {
			font-size: 14px;
			text-decoration: none;
		}

		& .icon {
			margin-right: 20px;
		}
	}
}

.bus-image {
	width: auto;
	height: 900px;
}