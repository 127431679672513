.clients {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 20px;
	@include media-breakpoint-down(md) {
		padding: 0px;
	}
	& h2 {
		margin-bottom: 30px;
	}

	& .client-wrapper {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;

		& .client {
			display: flex;
			aspect-ratio: auto 1 / 1;
			border: 1px solid $black;
			box-sizing: border-box;
			width: calc(20% - 55px);
			margin-bottom: 20px;
			padding: 40px;
			margin: 0 0 65px 50px;
			transition: all ease-in-out .2s;
			@include media-breakpoint-down(md) {
				padding: 0;
				margin-bottom: 0;
				margin: 10px;
				width: calc(50% - 40px);
				height: auto //calc(50% - 40px);
			}
			&:nth-child(5n+1) {
				margin: 0 0 65px 0;
				@include media-breakpoint-down(md) {
					margin: 10px;
				}
			}

			&:hover {
				box-shadow: 0px 0px 6px -4px rgba(0, 0, 0, 0.89);
			}
		}
	}
}
