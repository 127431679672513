
// Font
$font-size-p: 1rem;
$font-size-h1: 3rem;
$font-size-h2: 2rem;
$font-size-h3: 1rem;

$head-font: 'MachineStd', serif;
$text-font: 'localCalibri', 'sans-serif';

$padding-side: 60px;
$padding-side-mobile: 20px;

$padding-top: 20px;

$maxWidth: 1250px;
