.btn {
	display: flex;
	padding: 15px 40px;
	font-size: 16px;
	border: 1px solid $black;
	text-decoration: none;
	color: $black;
	font-family: $text-font;

	&:hover {
		color: $white;
		background-color: $black;
	}
}

.cta-buy {
	display: flex;
	position: fixed;
	right: -90px;
	bottom: 20px;
	justify-content: center;
	align-items: center;
	background: white;
	padding: 10px;
	border: .1px solid $black;
	border-right: 0px;
	cursor: pointer;
	z-index: 100;
	&:hover {
		right: -88px;
	}
	&.active {
		right: 0;
	}

	& a {
		margin-left: 20px;
		text-decoration: none;
		color: $black;
		font-family: $text-font;
		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 0.2em;
			background-color: #e2ddd9;
			opacity: 0;
			transition: opacity 300ms, transform 300ms;
		  }
		  &:hover::after,
		  &:focus::after {
			opacity: 1;
			transform: translate3d(0, 0.4em, 0);
		  }
	}
}


/* Common Mouse Scroll Ele */
.scroll-down {
	position: absolute;
	bottom: 0px;
	width: 75px;
	height: 80px;
	cursor: pointer;
	display: flex;
    justify-content: center;
}

.scroll-down {
	height: 100px;
}

.scroll-down span {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 60px;
	border: 2px solid #fff;
	border-radius: 50px;
	top: 5px;
}

.scroll-down span::after {
	position: absolute;
	content: "";
	top: 10px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 6px;
	height: 6px;
	border-radius: 100%;
	background: #fff;
	animation: animateMousePointer 1.5s infinite;
}

@keyframes animateMousePointer {
	0% {
		transform: translateY(20px);
	}

	50% {
		opacity: .7;
	}

	100% {
		opacity: 0;
		transform: translateY(0);
	}
}