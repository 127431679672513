.title {
    display: flex;
    width: 100%;
    justify-content: center;
    @include media-breakpoint-down(md) {
        padding: 20px;
    }
    & h2 {
        font-size: 55px;
        margin-bottom: 50px;
        @include media-breakpoint-down(md) {
			font-size: 45px;
            margin-bottom: 30px;
		}
    }
}