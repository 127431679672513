footer {
    display: flex;
    flex-direction: column;
    padding: 40px $padding-side;
    width: 100%;
    background-color: $blue;
    & h2 {
        color: $white;
        font-size: 28px;
        margin-bottom: 30px;
    }

    .footer-info {
        display: flex;

        & .contact {
            margin: 0 40px 0 0;

            & p {
                color: $white;
                font-size: 14px;
                margin-bottom: 5px;

                & a {
                    display: block;
                    color: $white;
                }
            }
        }

        & .details {
            color: $white;
            margin: 0 40px;

            & p {
                margin-bottom: 5px;
                font-size: 14px;
            }
        }

        .some {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            margin: 0 40px;

            & a {
                color: $white;
                text-decoration: none;
            }
        }
    }
}